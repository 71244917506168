import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Radio, Tab } from 'semantic-ui-react';

import styles from './PreferencesPane.module.scss';

const PreferencesPane = React.memo(({ tgActivateLink, subscribeToOwnCards, onUpdate }) => {
  const [t] = useTranslation();

  const handleSubscribeToOwnCardsChange = useCallback(() => {
    onUpdate({
      subscribeToOwnCards: !subscribeToOwnCards,
    });
  }, [subscribeToOwnCards, onUpdate]);
  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <Radio
        toggle
        checked={subscribeToOwnCards}
        label={t('common.subscribeToMyOwnCardsByDefault')}
        onChange={handleSubscribeToOwnCardsChange}
      />
      <div>
        <a
          className="ui fluid button CardModal_actionButton__1eB1l"
          target="blank_"
          href={tgActivateLink}
        >
          <i aria-hidden="true" className="paper plane outline icon CardModal_actionIcon__dnocs" />
          {t('telegram.activate_link')}
        </a>
      </div>
    </Tab.Pane>
  );
});

PreferencesPane.propTypes = {
  tgActivateLink: PropTypes.string.isRequired,
  subscribeToOwnCards: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default PreferencesPane;
